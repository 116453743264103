<template>
    <div class="sld_output">
        <MemberTitle :memberTitle="L['我的余额']" memberPath="/member/balance" memberTitleS="余额提现"></MemberTitle>
        <div class="main">
            <div v-show="showTip" class="tips flex_row_between_center">
                <span
                    >温馨提示：提现手续费为{{ info.extra }}元，最低提现金额为￥{{
                        info.minMoney ? Number(info.minMoney).toFixed(2) : 0
                    }}，新充值金额需在48小时后提现</span
                >
                <img @click="closeTip" src="@/assets/buy/close.png" />
            </div>
            <div class="box">
                <!-- <div class="item">
                <div class="left"><span>提现方式：</span></div>
                <div class="right"><span>{{info.type}}</span></div>
            </div> -->
                <div class="item">
                    <div class="left"><span>提现金额：</span></div>
                    <div class="right flex_column_start_start">
                        <!-- <input v-model="info.cashAmount" placeholder="请输入提现金额" maxlength="6" /> -->
                        <el-input type="number" v-model="cashNum" placeholder="请输入提现金额" @input="changnum" />
                        <div v-if="info.cashAmountErr" class="warning">{{ info.cashAmountErr }}</div>
                    </div>
                    <div class="money" v-if="info.cashAmount">
                        （本次提现手续费<span
                            >￥{{
                                info.extra ? ((Number(info.cashAmount) * Number(info.extra)) / 100).toFixed(2) : 0
                            }}</span
                        >）
                    </div>
                </div>
                <template v-if="!info.cashAmountErr">
                    <div v-if="info.cashAmount > balance_info.data.cashBalance" class="money_tip error">
                        剩余可提现金额不足
                    </div>
                    <div v-else class="money_tip">剩余可提现金额：￥{{ balance_info.data.cashBalance || 0 }}</div>
                </template>
                <!-- <div class="item">
                <div class="left"><span>支付宝账号：</span></div>
                <div class="right">
                    <input v-model="info.accountNumber" placeholder="请输入支付宝账号" maxlength="30" />
                    <div v-if="info.accountNumberErr" class="warning">{{info.accountNumberErr}}</div>
                </div>
            </div> -->
                <div class="item">
                    <div class="left"><span>支付密码：</span></div>
                    <div class="right">
                        <input v-model="PayPasswordVue" type="password" placeholder="请输入支付密码" maxlength="30" />
                        <!-- <div v-if="info.accountNumberErr" class="warning">{{info.accountNumberErr}}</div> -->
                    </div>
                </div>
                <div class="item">
                    <div class="left"><span>收款账户：</span></div>
                    <div class="right">
                        <el-select v-model="cardId" placeholder="收款人账户" v-if="!bankList.length"></el-select>
                        <el-select v-model="cardId" placeholder="收款人账户" v-else>
                            <!-- <el-select v-model="cardId" clearable placeholder="Select" > -->
                            <el-option
                                v-for="item in bankList"
                                :key="item.id"
                                :label="item.firmBankName"
                                :value="item.id"
                            >
                                <span style="float: left">{{ item.firmBankName }}</span>
                                <span style="float: right; color: var(--el-text-color-secondary); font-size: 13px">{{
                                    item.firmBankAccount.replace(/^(\d{4})\d+(\d{4})$/, "$1 **** **** $2")
                                }}</span>
                            </el-option>
                        </el-select>
                        <!-- <input v-model="info.accountNumber" placeholder="请输入支付宝账号" maxlength="30" /> -->
                        <div v-if="info.accountNumberErr" class="warning">{{ info.accountNumberErr }}</div>
                    </div>
                </div>

                <!-- <div class="item">
                <div class="left"><span>真实姓名：</span></div>
                <div class="right">
                    <input v-model="info.accountName" placeholder="请输入真实姓名" maxlength="20" />
                    <div v-if="info.accountNameErr" class="warning">{{info.accountNameErr}}</div>
                </div>
            </div>
            <div class="item">
                <div class="left"><span>支付密码：</span></div>
                <div class="right">
                    <input type="password" v-model="info.payPwd" placeholder="请输入支付密码" maxlength="20" />
                    <div v-if="info.payPwdErr" class="warning">{{info.payPwdErr}}</div>
                </div>
            </div> -->
            </div>
            <div class="btn" @click="submit2">申请提现</div>
        </div>
    </div>
</template>

<script>
import { getCurrentInstance, onMounted, reactive, ref } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import MemberTitle from "@/components/MemberTitle";
import { ElMessage } from "element-plus";
import { Encrypt, Decrypt } from "../../../utils/common.js";
export default {
    name: "OutputOperate",
    components: {
        MemberTitle
    },
    setup() {
        const { proxy } = getCurrentInstance();
        const L = proxy.$getCurLanguage();
        const showTip = ref(true);
        const router = useRouter();
        const store = useStore();
        const memberInfo = ref(store.state.memberInfo);
        const isClick = ref(false);
        const cardId = ref("");
        const cashNum = ref("");
        const is_show = ref(false);
        const info = reactive({
            minMoney: 0,
            extra: 0,
            type: "支付宝",
            cashAmount: "",
            cashAmountErr: "",
            accountNumber: "",
            accountNumberErr: "",
            accountName: "",
            accountNameErr: "",
            payPwd: "",
            payPwdErr: ""
        });

        const closeTip = () => {
            showTip.value = false;
        };

        //申请提现
        const submit = () => {
            if (isClick.value) {
                return;
            }
            let flag = true;
            console.log(info.cashAmount, Number(info.minMoney));
            if (!info.cashAmount) {
                info.cashAmountErr = "请输入提现金额";
                flag = false;
                return;
            } else if (info.cashAmount < Number(info.minMoney)) {
                info.cashAmountErr = "最低提现金额为￥" + Number(info.minMoney).toFixed(2);
                flag = false;
                return;
            } else if (info.cashAmount > Number(balance_info.data.cashBalance)) {
                info.cashAmountErr = "剩余可提现金额不足";
                flag = false;
                return;
            } else {
                info.cashAmountErr = "";
            }
            if (!info.accountNumber) {
                info.accountNumberErr = "请输入支付宝账号";
                flag = false;
                return;
            } else {
                info.accountNumberErr = "";
            }
            if (!info.accountName) {
                info.accountNameErr = "请输入真实姓名";
                flag = false;
                return;
            } else {
                info.accountNameErr = "";
            }
            if (!info.payPwd) {
                info.payPwdErr = "请输入支付密码";
                return;
            } else {
                isClick.value = true;
                proxy
                    .$get("v3/member/front/member/cash/log/verifyPwd", {
                        payPwd: info.payPwd
                    })
                    .then((res) => {
                        if (res.state == 200) {
                            info.payPwdErr = "";
                            if (flag) {
                                let param = {
                                    // accountId: memberInfo.value.memberId,
                                    cashAmount: info.cashAmount,
                                    accountNumber: info.accountNumber,
                                    accountName: info.accountName,
                                    payPwd: info.payPwd
                                };
                                proxy
                                    .$post("v3/member/front/member/cash/log/applyWithdraw", param)
                                    .then((res) => {
                                        if (res.state == 200) {
                                            ElMessage.success(res.msg);
                                            setTimeout(() => {
                                                router.back();
                                            }, 1000);
                                        } else {
                                            ElMessage(res.msg);
                                            isClick.value = false;
                                        }
                                    })
                                    .catch(() => {
                                        //异常处理
                                        isClick.value = false;
                                    });
                            } else {
                                isClick.value = false;
                            }
                        } else {
                            info.payPwdErr = res.msg || "请输入正确的支付密码";
                            isClick.value = false;
                        }
                    });
            }
        };

        const getSet = () => {
            proxy.$get("api/shopp/setting/getSettings", { names: "min_withdraw_amount,withdraw_fee" }).then((res) => {
                if (res.state == 200) {
                    if (res.data[0]) {
                        info.minMoney = res.data[0].value;
                    }
                    if (res.data[1]) {
                        info.extra = res.data[1].value;
                    }
                }
            });
        };
        const PayPasswordVue = ref("");
        // 申请提现
        const submit2 = () => {
            if (is_show.value) {
                return;
            }
            is_show.value = true;
            if (cashNum.value.length == 0) {
                ElMessage.warning("请输入提现金额");
                is_show.value = false;
                return;
            } else if (cashNum.value < Number(info.minMoney)) {
                ElMessage.warning("最低提现金额为￥" + Number(info.minMoney).toFixed(2));
                is_show.value = false;
                return;
            } else if (cashNum.value > Number(balance_info.data.cashBalance)) {
                ElMessage.warning("剩余可提现金额不足");
                is_show.value = false;
                return;
            }
            if (PayPasswordVue.value.length == 0) {
                ElMessage.warning("请输入支付密码");
                is_show.value = false;
                return;
            }
            if (!cardId.value) {
                ElMessage.warning("请选择收款人账户");
                is_show.value = false;
                return;
            }

            let param = {};
            param.cashAmount = cashNum.value;
            param.bankCardId = cardId.value;
            param.password = Encrypt(PayPasswordVue.value);
            proxy.$post("api/pay/pingan/bank/withdrawal", param, "json").then((res) => {
                if (res.code == 200) {
                    ElMessage.success(res.msg);
                    // bankList.value = res.data
                    setTimeout(() => {
                        is_show.value = false;
                        router.push("/member/balance");
                    }, 1000);
                } else {
                    is_show.value = false;
                    ElMessage.warning(res.msg);
                }
            });
        };
        const balance_info = reactive({ data: {} });
        const getBalanceinfo = () => {
            //获取余额信息
            proxy
                .$get("api/shopp/member/wallet")
                .then((res) => {
                    if (res.code == 200) {
                        // console.log(res.data);
                        // res.data.balanceAvailable = res.data.balanceAvailable.toFixed(2)
                        // res.data.cashAmt = res.data.cashAmt.toFixed(2)
                        balance_info.data = res.data;
                    } else {
                        ElMessage(res.msg);
                    }
                })
                .catch(() => {
                    //异常处理
                });
        };

        const changnum = () => {
            if (cashNum.value < 0) {
                cashNum.value = "";
            }
            //如果第一位是0，第二位不是点，就用数字把点替换掉
            let len1 = cashNum.value.substr(0, 1);
            let len2 = cashNum.value.substr(1, 1);
            if (cashNum.value.length > 1 && len1 == 0 && len2 != ".") {
                setTimeout(() => {
                    cashNum.value = cashNum.value.substr(1, 1);
                });
            }
            //小数点保留两位
            let val = cashNum.value.split(".");
            if (val.length == 2) {
                if (val[1].length > 1) {
                    setTimeout(() => {
                        cashNum.value = Number(cashNum.value).toFixed(2);
                    });
                }
            }
            if (Number(balance_info.data.cashBalance) < Number(cashNum.value)) {
                setTimeout(() => {
                    cashNum.value = balance_info.data.cashBalance;
                });
            }
        };
        // 获取银行卡列表
        const bankList = ref([]);
        const getBankInfo = async () => {
            proxy.$get("api/shopp/member/bank/card/list").then((res) => {
                if (res.code == 200) {
                    // ElMessage.success(res.msg)
                    // console.log(res)
                    bankList.value = res.data;
                } else {
                    ElMessage.warning(res.msg);
                }
            });
        };
        getBankInfo();
        onMounted(() => {
            getBalanceinfo();
            getSet();
        });
        return {
            L,
            memberInfo,
            balance_info,
            changnum,
            isClick,
            showTip,
            info,
            closeTip,
            submit,
            getSet,
            bankList,
            cardId,
            cashNum,
            submit2,
            PayPasswordVue,
            is_show
        };
    }
};
</script>

<style lang="scss">
.sld_output {
    .right {
        width: 360px;

        .el-select {
            width: 100%;
        }
    }
}
</style>

<style lang="scss" scoped>
.sld_output {
    width: 1007px;
    margin-left: 10px;
    float: left;

    .main {
        width: 100%;
        padding-top: 20px;
        overflow: hidden;
        background-color: white;

        .tips {
            margin-left: 20px;
            margin-right: 20px;
            padding: 14px;
            height: 40px;
            color: #000000;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            background: rgba(233, 32, 36, 0.1);
            border-radius: 3px;

            img {
                width: 13px;
                height: 13px;
                cursor: pointer;
            }
        }

        .box {
            margin-top: 20px;

            .money_tip {
                color: #999999;
                font-size: 13px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                margin-left: 333px;
                margin-top: 12px;

                &.error {
                    color: $colorMain;
                }
            }

            .item {
                margin-top: 20px;
                margin-left: 205px;
                display: flex;

                .left {
                    width: 110px;
                    height: 40px;
                    line-height: 40px;
                    margin-right: 18px;
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;

                    span {
                        position: relative;
                        text-align: right;

                        &:before {
                            display: block;
                            content: "*";
                            position: absolute;
                            left: -8px;
                            top: 50%;
                            height: 40px;
                            margin-top: -18px;
                            color: red;
                        }
                    }
                }

                .right {
                    width: 360px;

                    span {
                        height: 40px;
                        line-height: 40px;
                    }

                    input {
                        width: 360px;
                        height: 40px;
                        border: 1px solid #dddddd;
                        padding: 10px;
                        border-radius: 2px;
                    }

                    .warning {
                        position: relative;
                        left: 12px;
                        top: 8px;
                        line-height: 24px;
                        color: #666666;
                        font-size: 13px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: $colorMain;
                    }
                }

                .money {
                    line-height: 40px;
                    color: #999999;
                    font-size: 14px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    margin-left: 15px;

                    span {
                        color: $colorMain;
                        margin-left: 4px;
                    }
                }
            }
        }

        .btn {
            width: 170px;
            height: 40px;
            line-height: 40px;
            color: #fff;
            font-size: 18px;
            font-weight: bold;
            text-align: center;
            background: $colorMain;
            border-radius: 3px;
            margin: 80px auto;
            cursor: pointer;
        }
    }
}
</style>
<style>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
}
input[type="number"] {
    -moz-appearance: textfield;
}
</style>
